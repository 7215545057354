import * as React from 'react'
import PropTypes from 'prop-types'
import './button.css'

/**
 * Button Component. Create a stylized button
 * @param {Object} props - Object with these properties:
 *  @param {String} text - Text to display
 *  @param {String} color - Button color. Can be 'green', 'blue' or 'gradient'
 *  @param {String} fillMode - Button style. Can be 'fill' or 'outline'
 *  @param {String} size - Button size. Can be 'small' or 'normal'
 *  @param {Function} handleClick - Function to execute when the button is clicked
 * @returns Button as JSX Component
 */
const Button = ({ text, color, fillMode, size, handleClick }) => {
  const buttonStyles = {
    color: color || 'gradient',
    fillMode: fillMode || 'fill',
    size: size || 'normal'
  }

  handleClick = handleClick || function () {}
  return (<button className={'gl-button gl-button--' + buttonStyles.color + ' gl-button--' + buttonStyles.fillMode + ' gl-button--' + buttonStyles.size} onClick={handleClick}>{text}</button>)
}

Button.defaultProps = {
  text: '',
  color: 'gradient',
  fillMode: 'fill',
  size: 'normal'
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  fillMode: PropTypes.string,
  size: PropTypes.string,
  handleClick: PropTypes.func.isRequired
}

export default Button
