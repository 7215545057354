import * as React from 'react'
import { graphql, navigate } from 'gatsby'

import Layout from '../components/layout/layout'
import Seo from '../components/seo/seo'
import Button from '../components/button/button'

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const goToHome = () => {
    navigate('/')
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
          title='Esta página no existe - Gerson Lázaro'
          description='Desarrollador de software y contador de historias'
          url={location?.href}
          image="https://www.gersonlazaro.com/logo-gerson-lazaro.png"
          lang="es"
        />
      <div className='gl-404-error'>
        <div className='gl-404-error__container'>
          <h1 className='gl-404-error__title'>404: ESTA PUBLICACIÓN NO EXISTE...¿O SI?</h1>
          <p className='gl-404-error__text'>Nadie lo sabe, pero hay varias teorías al respecto. Según Niels Bohr en realidad la publicación existe y no existe pero solo cuando la abriste ha colapsado su función de onda, modificando su estado y haciendo que definitivamente no exista.</p>
          <p className='gl-404-error__text'>Sin embargo, Hugh Everett tiene una opinión diferente. Para él la función de onda no colapsa sino que sufre una ramificación, haciendo que en este universo no exista pero estableciendo que existe otro universo en el que si existe, y en él estás leyendo esta publicación. Desafortunadamente, estás en el universo en el cual no existe.</p>
          <p className='gl-404-error__text'>Roger Penrose considera más acertado a Bohr que a Everett. Sin embargo, no considera que la función de onda de la publicación haya colapsado cuando la abriste - así que si crees en Penrose, tranquilo, no fue tu culpa -. Según su opinión simplemente colapsó sin necesidad de que la abrierás. Igual iba a pasar.</p>
          <p className='gl-404-error__text'>Pero ¿Y si el problema no fuera la publicación, sino tú? Eso al menos es lo que opina Carlo Rovell. Para él la publicación siempre ha tenido su estado inexistente pero tú no podías saberlo hasta intentar abrirla y conocer su estado.</p>
          <strong className='gl-404-error__strong'>Desafortunadamente, sin importar cual de las teorías apoyes, lo único cierto es que en este momento no es posible acceder a la publicación que intentabas ver.</strong>
          <div className='gl-404-error__button-container'>
            <Button text='VOLVER AL INICIO' color='gradient' fillMode='fill' handleClick={goToHome} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
